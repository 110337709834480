module.exports = [{
      plugin: require('/media/hagn/horsedump/Projects/jsapp/th_front/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":640},
    },{
      plugin: require('/media/hagn/horsedump/Projects/jsapp/th_front/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/media/hagn/horsedump/Projects/jsapp/th_front/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
